<template>
  <div>
    <AppLoadingSpinner v-model="isLoading" />
    <v-card class="fixed-card">
      <v-card-title ref="cardTitle">
        量表预警管理
        <v-select
          class="filter-item"
          :items="categoryNameList"
          v-model="selectedCategoryName"
          dense
          solo
          clearable
          hide-details="auto"
          label="量表类型"
        ></v-select>
        <v-select
          class="filter-item"
          :items="lgState"
          v-model="value"
          text="text"
          value="value"
          dense
          solo
          hide-details="auto"
        ></v-select>
        <AppTooltipBtn
          rounded
          btn-class="ml-4"
          color="primary"
          icon="mdi-filter"
          label="查询"
          tooltip="批量删除被试者"
          @click="refreshLbGroupList"
        />
        <v-spacer></v-spacer>
        <v-text-field
          class="lbgroup-search-field"
          append-icon="mdi-magnify"
          label="搜索"
          single-line
          hide-details
          clearable
          v-model="lbGroupSearchText"
        ></v-text-field>
      </v-card-title>
      <v-card-text>
        <v-data-table
          class="mx-4"
          :height="getDataTableHeight()"
          :key="dataTableKey"
          :headers="nodeLbGroupHeaders"
          :items="lbGroupList"
          item-key="guid"
          :search="lbGroupSearchText"
          :loading="isLbGroupListLoading"
          :loading-text="`正在读取量表列表，请稍候...`"
          :no-data-text="`未找到任何量表`"
          :no-results-text="`未找到任何匹配的量表`"
          fixed-header
          hide-default-footer
          disable-pagination
        >
          <template v-slot:[`item.alertDispList`]="{ item }">
            <div v-if="item.alertDispList">
              <v-chip
                v-for="alert in item.alertDispList"
                :key="alert.alertGuid"
                class="ma-2"
                color="white"
                text-color="cyan"
                alert
                @click="showLbAlertDetails(alert.alertGuid, item.guid)"
              >
                {{ alert.alertName
                }}<v-avatar
                  right
                  :style="`background-color:${alert.alertColorHex}`"
                ></v-avatar>
              </v-chip>
            </div>
          </template>
          <template v-slot:[`item.guid`]="{ item }">
            <v-btn
              v-if="chargeShow('lb_alert_edit')"
              depressed
              color="primary"
              @click="showAlertDialog(item.guid)"
            >
              管理预警
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <AppDialog
      v-model="isShowAlerDialog"
      size="large"
      overflow-height="calc(100vh - 270px)"
      persistent
      :title="'管理量表预警'"
      text-class="px-10"
      color="success"
      :loading="isBtnLoading"
      cancel-text="关闭"
    >
      <AdminLbManageAlert :lb-guid="selectedLbGuid" />
    </AppDialog>
    <AppDialog
      v-model="isShowLbAlertDetailDialog"
      persistent
      size="big"
      overflow-height="calc(100vh - 270px)"
      :title="`预警详情`"
      cancel-text="关闭"
    >
      <LbAlertDetals
        :lb-alert-detail="selectedLbAlert"
        :score-field-name-items="scoreFieldNameItems"
      />
    </AppDialog>
    <AppMessageBox v-model="errorMsg" title="发生错误" />
  </div>
</template>
<script>
import _ from "lodash";
import AppLoadingSpinner from "@/components/AppLoadingSpinner";
import AppMessageBox from "@/components/AppMessageBox";
import AppTooltipBtn from "@/components/AppTooltipBtn";
import AdminLbManageAlert from "@/components/admin/AdminLbManageAlert.vue";
import LbAlertDetals from "@/components/admin/AdminLbAlertDetails";
import AppDialog from "@/components/AppDialog";
import {
  getLbAlerts,
  getLbCategoryList,
  getLbAlertListWithCategory,
  getLbAlertDetails,
  getLbScoreFieldNames
} from "@/api/lb";
import { mapGetters } from "vuex";
import { lbAlertAddState } from "@/utils/constants/lb";
import { lbManagePagePermission } from "@/api/permission";

export default {
  components: {
    AppLoadingSpinner,
    AppMessageBox,
    AppTooltipBtn,
    AppDialog,
    AdminLbManageAlert,
    LbAlertDetals
  },
  watch: {
    isShowAlerDialog(newVal) {
      if (!newVal) {
        this.reLoadAlertList();
      }
    }
  },
  data() {
    return {
      isShowLbAlertDetailDialog: false,
      isLoading: false,
      isDialogLoading: false,
      isHideWhenLoading: false,
      isLbGroupListLoading: false,
      isShowAlerDialog: false,
      isBtnLoading: false,
      lbGroupSearchText: "",
      dataTableKey: 0,
      selectedLbGuid: "",
      nodeLbGroupHeaders: [],
      lbGroupList: [],
      scoreFieldNameItems: [],
      errorMsg: "",
      categoryNameList: [], //绑定的类型名称
      selectedCategoryName: "", //选中的类型名称
      categoryList: [], //类型列表
      lgState: lbAlertAddState,
      value: "added",
      selectedLbAlert: {
        alertDisplay: {
          colorHex: "",
          displayName: "",
          guid: ""
        },
        alertConditionList: []
      }
    };
  },
  computed: {
    ...mapGetters({
      regionGuid: "user/regionGuid",
      nodeGuids: "user/loginNodeGuids"
    })
  },
  methods: {
    chargeShow(permission) {
      return lbManagePagePermission(permission);
    },
    async showLbAlertDetails(LbAlertDispGuid, SelectlbGuid) {
      try {
        this.isLoading = true;
        this.lbGuid = SelectlbGuid;
        this.scoreFieldNameItems = await getLbScoreFieldNames(SelectlbGuid);
        this.selectedLbAlert = await getLbAlertDetails(LbAlertDispGuid);
        // alert conditions 需要排序
        this.selectedLbAlert.alertConditionList.sort(
          (ac1, ac2) => ac1.logicOrder - ac2.logicOrder
        );
        this.isShowLbAlertDetailDialog = true;
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isLoading = false;
    },
    async reLoadAlertList() {
      let lbAlertList = await getLbAlerts(this.selectedLbGuid);
      if (lbAlertList.length <= 0) {
        this.lbGroupList = _.remove(this.lbGroupList, lb => {
          return lb.guid != this.selectedLbGuid;
        });
      } else {
        //let lbItem= _.find(this.lbGroupList,{ 'guid': this.selectedLbGuid});
        let newAlertList = [];
        for (let i = 0; i < lbAlertList.length; i++) {
          let newAlertItem = {
            alertColorHex: lbAlertList[i].alertDisplay.colorHex,
            alertGuid: lbAlertList[i].alertDisplay.guid,
            alertName: lbAlertList[i].alertDisplay.displayName
          };
          newAlertList.push(newAlertItem);
        }
        this.lbGroupList.forEach(element => {
          if (element.guid === this.selectedLbGuid) {
            element.alertDispList = newAlertList;
          }
        });
      }
    },
    showAlertDialog(lbGuid) {
      this.selectedLbGuid = lbGuid;
      this.isBtnLoading = true;
      this.isBtnLoading = true;
      this.isShowAlerDialog = true;
    },
    async getCategoryList() {
      try {
        this.isLoading = true;
        this.categoryList = await getLbCategoryList(this.regionGuid);
        this.categoryNameList = this.categoryList.map(list => list.displayName);
        //this.categoryNameList.push("无");
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isLoading = false;
    },
    //#region Refresh datatable
    getCardTitleHeight() {
      if (this.$refs.cardTitle) {
        return this.$refs.cardTitle.offsetHeight;
      }
      return 0;
    },
    getDataTableHeight() {
      var overflowHeight = 160 + this.getCardTitleHeight();
      return `calc(100vh - ${overflowHeight}px)`;
    },
    //#endregion
    async refreshLbGroupList() {
      try {
        this.isLbGroupListLoading = true;
        this.lbGroupList = await getLbAlertListWithCategory(
          this.nodeGuids[0],
          this.getCategoryGuid(),
          this.value
        );
        // 刷新 dataTable 控件的显示
        this.dataTableKey++;
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isLbGroupListLoading = false;
    },
    getCategoryGuid() {
      let guid = "";
      let cat = _.find(this.categoryList, {
        displayName: this.selectedCategoryName
      });
      if (cat) guid = cat.guid;
      return guid;
    },
    initNodeLbGroupHeaders() {
      this.nodeLbGroupHeaders = [
        {
          text: `量表名称`,
          value: "dispName",
          width: "300px"
        }
      ];
      this.nodeLbGroupHeaders.push({
        text: "预警列表",
        value: "alertDispList"
      });
      if (this.chargeShow("lb_alert_edit")) {
        this.nodeLbGroupHeaders.push({
          text: "管理预警",
          value: "guid",
          width: "200px"
        });
      }
    }
  },
  async created() {
    this.initNodeLbGroupHeaders();
    await this.getCategoryList();
    await this.refreshLbGroupList();
  }
};
</script>

<style lang="scss" scoped>
.fixed-card {
  height: calc(100vh - 125px);
}

.lbgroup-search-field {
  max-width: 300px;
}

.filter-item {
  margin-left: 20px;
  max-width: 300px;
  min-width: 150px;
}
</style>
