<template>
  <v-container>
    <AppLoadingSpinner v-model="isLoading" />
    <v-row>
      <v-col cols="3" sm="4">
        <v-card class="fixed-card-left">
          <v-card-title>
            <span>量表文档与设置</span>
            <v-spacer></v-spacer>
            <v-text-field
              class="lb-search-field"
              v-model="lbSearchText"
              append-icon="mdi-magnify"
              label="搜索"
              single-line
              hide-details
              clearable
              dense
            ></v-text-field>
          </v-card-title>
          <div></div>
          <v-divider></v-divider>
          <v-treeview
            class="fixed-tree-left py-3 text-body-2"
            transition
            :active.sync="lbInfoSelected"
            :search="lbSearchText"
            :items="lbInfoList"
            item-key="id"
            item-text="name"
            activatable
            open-on-click
          >
            <template v-slot:prepend="{ item, open }">
              <v-icon v-if="!item.icon" :key="item.id">
                {{ open ? "mdi-folder-open" : "mdi-folder" }}
              </v-icon>
              <v-icon v-else :key="item.id">
                {{ item.icon }}
              </v-icon>
            </template>
          </v-treeview>
        </v-card>
      </v-col>
      <v-col cols="9" sm="8">
        <v-card v-if="isShowLbDetailPanel">
          <v-tabs show-arrows>
            <v-tab>量表文档</v-tab>
            <v-tab v-if="hasLbAdminPerm">基本设置</v-tab>
            <v-tab-item>
              <v-card flat class="fixed-card-right py-4 px-6">
                <v-card-text v-html="lbDetails.htmlDoc"></v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item v-show="hasLbAdminPerm" class="fixed-card-right">
              <AdminLbManageInfo
                :lb-guid="selectedLbGuid"
                :lb-details="lbDetails"
                v-on:incre="chargeNeedChange"
              />
            </v-tab-item>
          </v-tabs>
        </v-card>
      </v-col>
    </v-row>
    <AppMessageBox v-model="errorMsg" title="发生错误" />
  </v-container>
</template>

<script>
import AppLoadingSpinner from "@/components/AppLoadingSpinner";
import AppMessageBox from "@/components/AppMessageBox";
import AdminLbManageInfo from "@/components/admin/AdminLbManageInfo.vue";
import _ from "lodash";
import { mapGetters } from "vuex";
import { getLbListWithCategory, getLbDetails } from "@/api/lb";
import { permissioNames } from "@/utils/constants/user";

export default {
  components: {
    AppLoadingSpinner,
    AppMessageBox,
    AdminLbManageInfo
  },

  data() {
    return {
      isLoading: false,
      isShowLbDetailPanel: false,
      lbInfoList: [],
      lbInfoSelected: [],
      lbDetails: {},
      lbSearchText: "",
      errorMsg: "",
      orgList: []
    };
  },

  watch: {
    lbInfoSelected() {
      this.errorMsg = "";
      this.lbDetails = {};
      this.getLbDocFromActiveId();
    }
  },

  computed: {
    ...mapGetters({
      regionGuid: "user/regionGuid",
      nodeGuids: "user/loginNodeGuids",
      permissions: "user/permissions"
    }),
    selectedLbGuid() {
      if (this.lbInfoSelected && this.lbInfoSelected.length) {
        return this.lbInfoSelected[0];
      }
      return "";
    },
    hasLbAdminPerm() {
      return (
        this.permissions.includes(permissioNames.lb_all.value) ||
        this.permissions.includes(permissioNames.lb_read.value) ||
        this.permissions.includes(permissioNames.lb_edit.value)
      );
    }
  },

  methods: {
    chargeNeedChange(obj) {
      let lbGuid = obj.lbGuid;
      let catGuid = obj.catGuid;
      let needChange = false;
      for (let i = 0; i < this.orgList.length; i++) {
        let lbList = this.orgList[i].lbDetailsList;
        let lbInfo = _.find(lbList, { lbGuid: lbGuid });
        if (lbInfo.category.guid != catGuid) {
          needChange = true;
          break;
        }
      }
      if (needChange) {
        let hasCat = false;
        let catName = "";
        for (let i = 0; i < this.orgList.length; i++) {
          let lbList = this.orgList[i].lbDetailsList;
          let lbListWithCat = lbList.filter(function(l) {
            return l.category.guid === catGuid;
          });
          if (lbListWithCat.length > 0) {
            catName = lbListWithCat[0].category.displayName;
            hasCat = true;
            break;
          }
        }
        if (hasCat) {
          let midList = [];
          for (let i = 0; i < this.orgList.length; i++) {
            let lbList = [];
            this.orgList[i].lbDetailsList.forEach(element => {
              if (element.lbGuid === lbGuid) {
                element.category.guid = catGuid;
                element.category.displayName = catName;
              }
              lbList.push(element);
            });
            let n = {
              nodeGuid: this.orgList[i].nodeGuid,
              nodeName: this.orgList[i].nodeGuid,
              lbDetailsList: lbList
            };
            midList.push(n);
          }
          this.orgList = midList;
          this.chainTreeList(this.orgList);
        } else {
          this.getAllLbList();
        }
      }
    },
    async getAllLbList() {
      try {
        this.isLoading = true;
        let lbList = await getLbListWithCategory(this.nodeGuids);
        this.orgList = lbList;
        await this.chainTreeList(lbList);
      } catch (err) {
        this.errorMsg = err;
      }
      this.isLoading = false;
    },
    async chainTreeList(orgList) {
      // 如果只有一个 Node，则直接显示量表集合
      if (orgList.length > 1) {
        this.lbInfoList = _.chain(orgList)
          .map(lb => ({
            id: lb.nodeGuid,
            name: lb.nodeName,
            children: this.extractLbCategoryList(lb.lbDetailsList)
          }))
          .value();
      } else if (orgList.length === 1) {
        let lb = orgList[0];
        this.lbInfoList = this.extractLbCategoryList(lb.lbDetailsList);
      }
    },
    extractLbCategoryList(lbDetailsList) {
      return _.chain(lbDetailsList)
        .groupBy("category.displayName")
        .map((lb, lbCatName) => ({
          id: lbCatName,
          name: lbCatName,
          children: lb.map(l => ({
            id: l.lbGuid,
            name: l.dispName,
            icon: "mdi-file-document-outline"
          }))
        }))
        .value();
    },
    async getLbDocFromActiveId() {
      this.isShowLbDetailPanel = false;
      if (this.selectedLbGuid) {
        try {
          this.isLoading = true;
          this.lbDetails = await getLbDetails(this.selectedLbGuid);
          this.isShowLbDetailPanel = true;
        } catch (err) {
          this.errorMsg = err;
        }
        this.isLoading = false;
      }
    }
  },

  created() {
    this.getAllLbList();
  }
};
</script>

<style lang="scss" scoped>
.fixed-card-left {
  overflow: auto;
  .fixed-tree-left {
    height: calc(100vh - 240px);
    min-width: 250px;
  }
}
.fixed-card-right {
  height: calc(100vh - 220px);
  overflow: auto;
}
.lb-search-field {
  max-width: 120px;
}
</style>
