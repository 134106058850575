<template>
  <div>
    <AppLoadingSpinner v-model="isLoading" />
    <v-form>
      <v-row no-gutters>
        <v-col cols="12">
          <v-text-field
            disabled
            v-model="lbAlertDetail.alertDisplay.displayName"
            label="预警描述"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row class="mb-10">
        <v-col cols="12">
          <span class="text-body-2">预警颜色</span>
          <v-icon :color="lbAlertDetail.alertDisplay.colorHex">
            mdi-record
          </v-icon>
        </v-col>
      </v-row>
      <v-row
        no-gutters
        v-for="(alertCondition, idx) in lbAlertDetail.alertConditionList"
        :key="idx"
      >
        <!-- 第一行没有 logic operator -->
        <v-col cols="2" class="mr-6">
          <v-select
            v-if="idx > 0"
            dense
            disabled
            :items="logicOperatorItems"
            v-model="alertCondition.logicOperator"
            label="关系符"
          ></v-select>
        </v-col>
        <v-col cols="4" class="mr-3">
          <v-select
            dense
            disabled
            :items="scoreFieldNameItems"
            v-model="alertCondition.scoreFieldName"
            label="预警字段"
          ></v-select>
        </v-col>
        <v-col cols="2" class="mr-3">
          <v-select
            dense
            disabled
            :items="scoreOperatorItems"
            v-model="alertCondition.scoreOperator"
            label="比较符号"
          ></v-select>
        </v-col>
        <v-col cols="2" class="mr-3">
          <v-text-field
            dense
            disabled
            v-model="alertCondition.scoreValue"
            label="预警分值"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-form>
    <AppMessageBox v-model="errorMsg" title="发生错误" />
  </div>
</template>
<script>
import AppLoadingSpinner from "@/components/AppLoadingSpinner";
import AppMessageBox from "@/components/AppMessageBox";
export default {
  components: {
    AppLoadingSpinner,
    AppMessageBox
  },
  props: {
    lbAlertDetail: {
      type: Object,
      required: true
    },
    scoreFieldNameItems: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      deletingLbAlertList: [],
      scoreOperatorItems: ["=", "<", "<=", ">", ">="],
      logicOperatorItems: ["and", "or"],
      isLoading: false,
      errorMsg: ""
    };
  }
};
</script>
