<template>
  <div>
    <AppLoadingSpinner v-model="isLoading" />
    <v-form ref="lbInfoForm">
      <v-card flat class="mx-4 my-4" max-width="600px">
        <v-card-title>基本信息</v-card-title>
        <v-alert
          type="success"
          dense
          transition="slide-y-transition"
          :value="isUpdateSuccess"
        >
          更新成功！
        </v-alert>
        <div class="px-6">
          <v-text-field
            :disabled="!chargeShow('lb_edit')"
            filled
            dense
            label="量表显示名称"
            v-model="localLbDetails.dispName"
            :rules="fieldRules.dispName"
          ></v-text-field>
          <v-select
            :disabled="!chargeShow('lb_edit')"
            filled
            dense
            label="量表分类"
            v-model="localLbDetails.category.guid"
            :items="lbCatList"
            item-value="guid"
            item-text="displayName"
          ></v-select>
          <v-text-field
            :disabled="!chargeShow('lb_edit')"
            filled
            dense
            label="适用范围"
            v-model="localLbDetails.scopeDesc"
            :rules="fieldRules.scopeDesc"
          ></v-text-field>
          <v-textarea
            :disabled="!chargeShow('lb_edit')"
            filled
            dense
            rows="2"
            label="描述"
            v-model="localLbDetails.description"
            :rules="fieldRules.description"
          ></v-textarea>
          <v-card-title><small>关键字</small></v-card-title>
          <v-card-text>
            <div>
              <v-chip
                v-for="(word, idx) in localLbDetails.keywords"
                :key="idx"
                class="mr-2 mb-4"
                :close="chargeShow('lb_edit')"
                @click:close="localLbDetails.keywords.splice(idx, 1)"
              >
                {{ word }}
              </v-chip>
            </div>
            <v-text-field
              v-if="chargeShow('lb_edit')"
              dense
              label="关键字添加"
              hint="请输入关键字候选"
              v-model="keywords"
            >
              <template v-slot:append-outer>
                <v-btn
                  v-if="chargeShow('lb_edit')"
                  text
                  small
                  color="primary"
                  @click="addKeyWord"
                >
                  <v-icon>mdi-plus</v-icon>
                  添加
                </v-btn>
              </template>
            </v-text-field>
          </v-card-text>
          <v-switch
            :disabled="!chargeShow('lb_edit')"
            inset
            :ripple="false"
            label="是否设为“常用量表”"
            v-model="localLbDetails.isTopMost"
          ></v-switch>
          <v-switch
            :disabled="!chargeShow('lb_edit')"
            v-if="!!localLbDetails.isShuffleAvailable"
            inset
            :ripple="false"
            label="是否使用“乱序答题”模式"
            v-model="localLbDetails.isQuesShuffle"
          ></v-switch>
          <v-switch
            :disabled="!chargeShow('lb_edit')"
            inset
            :ripple="false"
            label="答题时，是否显示完成进度"
            v-model="localLbDetails.isShowProgress"
          ></v-switch>
        </div>
        <v-card-title>答题时间限制（0 表示无限制）</v-card-title>
        <div class="time-picker px-6 pt-2">
          <v-text-field
            :disabled="!chargeShow('lb_edit')"
            class="mx-4 time-picker-input"
            outlined
            dense
            label="分钟"
            v-model="lbLimitTime.minutes"
            :rules="fieldRules.timeBoxMinutes"
          >
            <template v-slot:prepend-inner>
              <v-btn
                v-if="chargeShow('lb_edit')"
                icon
                color="green"
                small
                @click="lbLimitTime.minutes--"
              >
                <v-icon>mdi-minus</v-icon>
              </v-btn>
            </template>
            <template v-slot:append>
              <v-btn
                v-if="chargeShow('lb_edit')"
                icon
                color="red"
                small
                @click="lbLimitTime.minutes++"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </template>
          </v-text-field>
        </div>
        <v-card-actions class="ml-4 mb-6">
          <v-btn
            v-if="chargeShow('lb_edit')"
            color="primary"
            depressed
            @click="saveLbDetails"
            :disabled="isBtnLoading"
            :loading="isBtnLoading"
            >确认修改</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-form>
    <AppMessageBox v-model="errorMsg" title="发生错误" />
  </div>
</template>

<script>
import AppLoadingSpinner from "@/components/AppLoadingSpinner";
import AppMessageBox from "@/components/AppMessageBox";
import { mapGetters } from "vuex";
import { getLbCategoryList, updateLbDetails } from "@/api/lb";
import { splitSecondToTime, mergeTimeToSecond } from "@/utils/dateTime";
import { lbManagePagePermission } from "@/api/permission";

export default {
  components: {
    AppLoadingSpinner,
    AppMessageBox
  },

  props: {
    lbGuid: {
      type: String,
      required: true
    },
    lbDetails: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      isLoading: false,
      isBtnLoading: false,
      localLbDetails: this.lbDetails,
      lbLimitTime: {
        minutes: 0,
        seconds: 0,
        isNegative: false
      },
      lbCatList: [],
      fieldRules: {
        dispName: [val => (val || "").length > 0 || "量表显示名称不能为空"],
        timeBoxMinutes: [
          val =>
            (!isNaN(Number(val)) && Number(val) >= 0) || "必须输入大于零的数字",
          val => Number(val) < 999 || "答题时间不能大于999分钟"
        ]
      },
      isUpdateSuccess: false,
      errorMsg: "",
      keywords: ""
    };
  },

  watch: {
    lbDetails: {
      handler(newVal) {
        this.localLbDetails = newVal;
        this.$refs.lbInfoForm && this.$refs.lbInfoForm.resetValidation();
      },
      immediate: true,
      deep: true
    },
    "lbLimitTime.minutes": {
      handler() {
        if (this.lbLimitTime.minutes >= 0) {
          this.localLbDetails.timeBoxSecond = mergeTimeToSecond(
            this.lbLimitTime
          );
        }
      }
    }
  },

  computed: {
    ...mapGetters({
      regionGuid: "user/regionGuid"
    })
  },

  methods: {
    chargeShow(permission) {
      return lbManagePagePermission(permission);
    },
    newCatGuidSend() {
      let data = {
        lbGuid: this.lbGuid,
        catGuid: this.localLbDetails.category.guid
      };
      this.$emit("incre", data); //获取父组件的方法，动态获取父组件的num值
    },
    addKeyWord() {
      if (this.keywords) {
        this.localLbDetails.keywords.push(this.keywords);
        this.keywords = "";
      }
    },
    showSuccessMsg() {
      this.isUpdateSuccess = true;
      setTimeout(() => (this.isUpdateSuccess = false), 2000);
    },
    async getAllLbCatList() {
      try {
        this.isLoading = true;
        this.lbCatList = await getLbCategoryList(this.regionGuid);
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isLoading = false;
    },
    async saveLbDetails() {
      let isValid = this.$refs.lbInfoForm.validate();
      if (isValid) {
        try {
          this.isBtnLoading = true;
          await updateLbDetails({
            lbGuid: this.lbGuid,
            dispName: this.localLbDetails.dispName,
            timeBoxSecond: Number(this.localLbDetails.timeBoxSecond),
            isTopMost: this.localLbDetails.isTopMost,
            isQuesShuffle: this.localLbDetails.isQuesShuffle,
            isShowProgress: this.localLbDetails.isShowProgress,
            scopeDesc: this.localLbDetails.scopeDesc,
            keywords: this.localLbDetails.keywords, //将关键字转换成数组
            description: this.localLbDetails.description,
            category: this.localLbDetails.category
          });
          this.showSuccessMsg();
          this.newCatGuidSend();
        } catch (err) {
          this.errorMsg = err.message;
        }
        this.isBtnLoading = false;
      }
    }
  },
  created() {
    this.lbLimitTime = splitSecondToTime(
      Number(this.localLbDetails.timeBoxSecond)
    );
    this.getAllLbCatList();
  }
};
</script>

<style lang="scss">
.time-picker {
  max-width: 600px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  .time-picker-input {
    max-width: 150px;
    min-width: 120px;
    input {
      text-align: center;
    }
  }
}
</style>
