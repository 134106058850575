import { render, staticRenderFns } from "./AdminLbCategory.vue?vue&type=template&id=757dc4d6&scoped=true&"
import script from "./AdminLbCategory.vue?vue&type=script&lang=js&"
export * from "./AdminLbCategory.vue?vue&type=script&lang=js&"
import style0 from "./AdminLbCategory.vue?vue&type=style&index=0&id=757dc4d6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "757dc4d6",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VEditDialog } from 'vuetify/lib/components/VDataTable';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VCard,VCardText,VCardTitle,VDataTable,VEditDialog,VSpacer,VTextField,VTextarea})
