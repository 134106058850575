<template>
  <div>
    <AppLoadingSpinner v-model="isLoading" />
    <v-card flat class="mx-4 my-4">
      <v-card-title>
        预警设置
        <v-spacer></v-spacer>
        <v-btn text color="success" @click="openAlertCreationDialog">
          <v-icon class="mr-2">mdi-plus</v-icon>
          添加预警
        </v-btn>
      </v-card-title>
      <v-alert
        type="success"
        dense
        transition="slide-y-transition"
        :value="isUpdateSuccess"
      >
        更新成功！
      </v-alert>
      <v-expansion-panels accordion class="mt-6 mb-10" v-model="openPanelIndex">
        <v-expansion-panel v-for="(lbAlert, idx) in lbAlertList" :key="idx">
          <v-expansion-panel-header v-slot="{ open }">
            <v-row no-gutters>
              <v-col cols="3">
                <v-fade-transition leave-absolute>
                  <span v-if="!open">
                    {{ lbAlert.alertDisplay.displayName }}
                  </span>
                  <span v-else>修改预警设置</span>
                </v-fade-transition>
              </v-col>
              <v-col cols="4">
                <v-fade-transition leave-absolute>
                  <v-icon
                    v-if="!open"
                    :key="colorUpdateKey"
                    :color="lbAlert.alertDisplay.colorHex"
                  >
                    mdi-record
                  </v-icon>
                </v-fade-transition>
              </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-form :ref="`lbAlertsForm${idx}`">
              <v-row no-gutters>
                <v-col cols="6">
                  <v-text-field
                    v-model="lbAlert.alertDisplay.displayName"
                    label="预警描述"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class="mb-10">
                <v-col cols="6">
                  <span class="text-body-2">预警颜色（点击修改）</span>
                  <v-btn icon class="ml-12" @click="openColorPickDialog">
                    <v-icon
                      :key="colorUpdateKey"
                      :color="lbAlert.alertDisplay.colorHex"
                    >
                      mdi-record
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-row
                no-gutters
                v-for="(alertCondition, idx) in lbAlert.alertConditionList"
                :key="idx"
              >
                <!-- 第一行没有 logic operator -->
                <v-col cols="2" class="mr-6">
                  <v-select
                    v-if="idx > 0"
                    dense
                    :items="logicOperatorItems"
                    v-model="alertCondition.logicOperator"
                    label="关系符"
                    :rules="fieldRules.noEmpty"
                  ></v-select>
                </v-col>
                <v-col cols="3" class="mr-3">
                  <v-select
                    dense
                    :items="scoreFieldNameItems"
                    v-model="alertCondition.scoreFieldName"
                    label="预警字段"
                    :rules="fieldRules.noEmpty"
                  ></v-select>
                </v-col>
                <v-col cols="2" class="mr-3">
                  <v-select
                    dense
                    :items="scoreOperatorItems"
                    v-model="alertCondition.scoreOperator"
                    label="比较符号"
                    :rules="fieldRules.noEmpty"
                  ></v-select>
                </v-col>
                <v-col cols="2" class="mr-3">
                  <v-text-field
                    dense
                    v-model="alertCondition.scoreValue"
                    label="预警分值"
                    :rules="fieldRules.number"
                  ></v-text-field>
                </v-col>
                <!-- 第一行无法删除 -->
                <v-col cols="1">
                  <v-btn
                    v-if="idx > 0"
                    icon
                    color="error"
                    @click="removeOneAlertCondition(idx)"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="2">
                  <v-btn text color="primary" @click="addOneAlertCondition">
                    <v-icon class="mr-2">mdi-plus</v-icon>
                    添加预警字段
                  </v-btn>
                </v-col>
              </v-row>
              <v-row class="mt-6">
                <v-col cols="6">
                  <v-btn color="primary" @click="saveUpdatedAlert">
                    确认修改
                  </v-btn>
                  <v-btn
                    text
                    color="error"
                    class="ml-6"
                    @click="isShowDeleteConfirmDialog = true"
                  >
                    <v-icon class="mr-2">mdi-delete</v-icon>
                    删除预警
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>
    <AppDialog
      v-model="isShowColorPickerDialog"
      size="small"
      title="请选择预警的颜色"
      color="primary"
      action-text="确定"
      @confirm="colorPickConfirmed"
    >
      <v-color-picker
        v-model="pickedColorHex"
        show-swatches
        hide-canvas
        hide-mode-switch
      ></v-color-picker>
    </AppDialog>
    <AppDialog
      v-model="isShowAlertDispCreateDialog"
      size="small"
      title="添加新的预警"
      color="primary"
      action-text="确定"
      @confirm="alertCreationConfirmed"
    >
      <v-text-field
        v-model="newAlertDispName"
        label="预警描述"
        :rules="fieldRules.noEmpty"
      ></v-text-field>
      <span class="text-body-1">预警颜色</span>
      <v-color-picker
        v-model="newAlertColorHex"
        show-swatches
        hide-canvas
        hide-mode-switch
      ></v-color-picker>
    </AppDialog>
    <AppDialog
      v-model="isShowDeleteConfirmDialog"
      size="small"
      title="确定要删除这个预警吗？"
      color="error"
      action-text="确认删除"
      @confirm="removeOneAlertDispConfirmed"
    >
      删除后无法恢复
    </AppDialog>
    <AppMessageBox v-model="errorMsg" title="发生错误" />
  </div>
</template>

<script>
import AppLoadingSpinner from "@/components/AppLoadingSpinner";
import AppMessageBox from "@/components/AppMessageBox";
import AppDialog from "@/components/AppDialog";
import {
  getLbAlerts,
  getLbScoreFieldNames,
  updateLbAlertDisp,
  updateLbAlertConditions,
  removeLbAlertDisp,
  removeLbAlertConditions
} from "@/api/lb";

export default {
  components: {
    AppLoadingSpinner,
    AppMessageBox,
    AppDialog
  },

  props: {
    lbGuid: {
      type: String,
      required: true
    }
  },
  watch: {
    lbGuid(newVal) {
      if (newVal) this.loadLbAlerts();
    }
  },

  data() {
    return {
      isLoading: false,
      openPanelIndex: -1,
      lbAlertList: [],
      deletingLbAlertList: [],
      scoreFieldNameItems: [],
      scoreOperatorItems: ["=", "<", "<=", ">", ">="],
      logicOperatorItems: ["and", "or"],
      fieldRules: {
        noEmpty: [val => (val || "").length > 0 || "不能为空"],
        number: [
          val => /^\d{1,}\.\d+|\d+$/.test(val) || "请输入正确的分值(可以是小数)"
        ]
      },
      isUpdateSuccess: false,
      // alert creation
      newAlertDispName: "",
      newAlertColorHex: "",
      // dialogs
      isShowColorPickerDialog: false,
      isShowAlertDispCreateDialog: false,
      isShowDeleteConfirmDialog: false,
      pickedColorHex: "",
      colorUpdateKey: -1,
      errorMsg: ""
    };
  },

  computed: {
    activeLbAlert() {
      if (this.openPanelIndex > -1) {
        return this.lbAlertList[this.openPanelIndex];
      }
      return {
        alertDisplay: {},
        alertConditionList: []
      };
    }
  },

  methods: {
    showSuccessMsg() {
      this.isUpdateSuccess = true;
      setTimeout(() => (this.isUpdateSuccess = false), 2000);
    },
    mapToBackendAlertCondList(alertDispGuid, alertCondList) {
      return alertCondList.map(ac => {
        if (!ac.alertDispGuid) {
          ac.alertDispGuid = alertDispGuid;
        }
        if (!ac.lbInfoGuid) {
          ac.lbInfoGuid = this.lbGuid;
        }
        ac.logicOrder = Number(ac.logicOrder);
        ac.scoreValue = Number(ac.scoreValue);
        return ac;
      });
    },
    ///////////////////// Loading //////////////////////
    async loadLbAlerts() {
      try {
        this.isLoading = true;
        this.lbAlertList = await getLbAlerts(this.lbGuid);
        // alert conditions 需要排序
        for (let lbAlert of this.lbAlertList) {
          lbAlert.alertConditionList.sort(
            (ac1, ac2) => ac1.logicOrder - ac2.logicOrder
          );
        }
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isLoading = false;
    },
    async loadLbFieldNames() {
      try {
        this.isLoading = true;
        this.scoreFieldNameItems = await getLbScoreFieldNames(this.lbGuid);
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isLoading = false;
    },
    /////////////////// Create Alert //////////////////////
    openAlertCreationDialog() {
      this.newAlertDispName = "";
      this.newAlertColorHex = "#000000"; // 默认为黑色
      this.isShowAlertDispCreateDialog = true;
    },
    alertCreationConfirmed() {
      this.lbAlertList.push({
        alertDisplay: {
          displayName: this.newAlertDispName,
          colorHex: this.newAlertColorHex
        },
        alertConditionList: []
      });
      this.$nextTick(() => {
        this.newAlertDispName = "";
        this.newAlertColorHex = "#000000"; // 重置为黑色
        this.isShowAlertDispCreateDialog = false;
        // 展开刚才添加的预警项
        this.openPanelIndex = this.lbAlertList.length - 1;
      });
    },
    /////////////////// Update Alert //////////////////////
    openColorPickDialog() {
      this.pickedColorHex = this.activeLbAlert.alertDisplay.colorHex;
      this.isShowColorPickerDialog = true;
    },
    colorPickConfirmed() {
      this.isShowColorPickerDialog = false;
      this.$nextTick(() => {
        this.activeLbAlert.alertDisplay.colorHex = this.pickedColorHex;
        // 修改 key 值会让控件强制刷新
        this.colorUpdateKey = new Date().getTime();
      });
    },
    addOneAlertCondition() {
      this.activeLbAlert.alertConditionList.push({
        logicOrder: this.activeLbAlert.alertConditionList.length,
        logicOperator: "",
        scoreFieldName: "",
        scoreOperator: "",
        scoreValue: 0
      });
    },
    /////////////////// Delete Alert Disp /////////////////////////
    async removeOneAlertDispConfirmed() {
      try {
        let removingAlertDisp = this.lbAlertList[this.openPanelIndex];
        if (
          removingAlertDisp.alertDisplay &&
          removingAlertDisp.alertDisplay.guid
        ) {
          await removeLbAlertDisp({
            guid: removingAlertDisp.alertDisplay.guid
          });
        }
        this.lbAlertList.splice(this.openPanelIndex, 1);
        this.isShowDeleteConfirmDialog = false;
      } catch (err) {
        this.errorMsg = err.message;
      }
    },
    /////////////////// Delete Alert /////////////////////////
    removeOneAlertCondition(removeIndex) {
      let condList = this.activeLbAlert.alertConditionList;
      let deletingAlert = condList[removeIndex];
      if (deletingAlert) {
        this.deletingLbAlertList.push(deletingAlert);
      }
      condList.splice(removeIndex, 1);
      this.activeLbAlert.alertConditionList = condList.map((cond, index) => {
        cond.logicOrder = index;
        return cond;
      });
    },
    /////////////////// Save Changes /////////////////////////
    async saveUpdatedAlert() {
      let formRefs = this.$refs[`lbAlertsForm${this.openPanelIndex}`];
      if (formRefs && formRefs.length) {
        let isValid = formRefs[0].validate();
        if (isValid) {
          try {
            this.isLoading = true;
            let alertDispGuid = await updateLbAlertDisp({
              alertDispGuid: this.activeLbAlert.alertDisplay.guid,
              displayName: this.activeLbAlert.alertDisplay.displayName,
              colorHex: this.activeLbAlert.alertDisplay.colorHex
            });
            let alertCondList = this.activeLbAlert.alertConditionList;
            await updateLbAlertConditions(
              this.mapToBackendAlertCondList(alertDispGuid, alertCondList)
            );
            if (this.deletingLbAlertList.length) {
              await removeLbAlertConditions(
                this.mapToBackendAlertCondList(
                  alertDispGuid,
                  this.deletingLbAlertList
                )
              );
              this.deletingLbAlertList = [];
            }
            // 刷新页面
            await this.loadLbAlerts();
            this.showSuccessMsg();
          } catch (err) {
            this.errorMsg = err.message;
          }
          this.isLoading = false;
        }
      }
    }
  },

  created() {
    this.loadLbAlerts();
    this.loadLbFieldNames();
    this.colorUpdateKey = new Date().getTime();
  }
};
</script>
