import { get, post } from "@/utils/axios.js";

export function getLbAlertDetails(lbAlertDispGuid) {
  return get(`/lbalert/GetLbAlertItemFromAlertDispGuid/${lbAlertDispGuid}`);
}

export async function DeleteOneLbCategory(lbCategoryGuid) {
  let response = await post("/lbmanage/DeleteOneLbCategory", {
    guid: lbCategoryGuid
  });
  return response.guid;
}

export async function createLbCategory(customerGuid, dispName, description) {
  let response = await post("/lbmanage/CreateLbCategory", {
    customerGuid,
    displayName: dispName,
    description
  });

  return response.guid;
}

export function fetchLbCategoryInfo(lbCategoryGuid) {
  return get("/lbmanage/LbCategoryInfoFromGuid", { lbCategoryGuid });
}

export async function updateLbCategoryDescription(lbCategoryGuid, description) {
  let response = await post(
    `/lbmanage/UpdateLbCategoryDescription/${lbCategoryGuid}`,
    {
      value: description
    }
  );
  return response.guid;
}

export async function updateLbCategoryDisplayName(lbCategoryGuid, displayName) {
  let response = await post(
    `/lbmanage/UpdateLbCategoryDisplayName/${lbCategoryGuid}`,
    {
      value: displayName
    }
  );
  return response.guid;
}

export function getLbAlertListWithCategory(nodeGuid, catGuid, alertState) {
  return post("/lbmanage/LbInfoAlertListFromCategory", {
    nodeGuid,
    categoryGuid: catGuid,
    alertState
  });
}

export function getLbListWithCategory(nodeGuids) {
  return post("/lbmanage/LbInfoWithCategory", nodeGuids);
}

export function getLbDetails(lbGuid) {
  return get(`/lbmanage/LbDetails/${lbGuid}`);
}

export function getLbCategoryList(regionGuid) {
  return get(`/lbmanage/AllLbCategoryList/${regionGuid}`);
}

export function getLbScoreFieldNames(lbGuid) {
  return get(`/lbmanage/LbScoreFieldNames/${lbGuid}`);
}

export function updateLbDetails({
  lbGuid,
  dispName,
  timeBoxSecond,
  isTopMost,
  isQuesShuffle,
  isShowProgress,
  scopeDesc,
  keywords,
  description,
  category
}) {
  return post("/lbmanage/UpdateLbDetails", {
    lbGuid,
    dispName,
    timeBoxSecond,
    isTopMost,
    isQuesShuffle,
    isShowProgress,
    scopeDesc,
    keywords,
    description,
    category
  });
}

export function getLbAlerts(lbGuid) {
  return get(`/lbalert/LbAlerts/${lbGuid}`);
}

export function getAlertDispListForFilter(
  regionGuid,
  nodeGuidList,
  testStatusList
) {
  return post("/lbalert/AlertDispListFromNodeAndCaseStatus", {
    customerGuid: regionGuid,
    nodeGuidList,
    testStatusList
  });
}

export async function updateLbAlertDisp({
  alertDispGuid,
  displayName,
  colorHex
}) {
  let response = await post("/lbalert/UpdateLbAlertDisplay", {
    guid: alertDispGuid,
    displayName,
    colorHex
  });
  return response.guid;
}

/**
 * @param {*} conditionList
 * {
 *    alertDispGuid: String,
 *    scoreFieldName: String,
 *    scoreOperator: String,
 *    scoreValue: Number,
 *    logicOrder: Number,
 *    logicOperator: String
 * }
 * @returns
 */
export async function updateLbAlertConditions(conditionList) {
  let response = await post("/lbalert/UpdateLbAlertConditions", conditionList);
  return response.value;
}

export async function removeLbAlertDisp({ guid }) {
  let response = await post("/lbalert/RemoveOneLbAlertDisplay", {
    guid
  });
  return response.value;
}

export async function removeLbAlertConditions(conditionGuidList) {
  let response = await post(
    "/lbalert/RemoveLbAlertConditions",
    conditionGuidList
  );
  return response.value;
}
